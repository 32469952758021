import React from "react"
import "./getting-there.scss"
import Header from "../components/header/Header"
import Navbar from "../components/navbar/navbar"

import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Footer from "../components/footer/footer"
import { options } from "../utils/queryUtils"

export const MyQuery = graphql`
  query hello {
    allContentfulLocation {
      edges {
        node {
          location {
            raw
          }
        }
      }
    }
  }
`
const GettingThere = ({ data }) => {
  return (
    <div>
      <Header />
      <Navbar />
      <div className="main-container">
        <div className="main-container__header">Getting There</div>
        <div className="main-container__location">
          <div className="main-container__location-container">
            <div className="location-copy">
              <h4 className="main-container__header">Location: </h4>
              {data.allContentfulLocation.edges[0].node.location &&
                renderRichText(
                  data?.allContentfulLocation?.edges[0]?.node?.location,
                  options
                )}
            </div>
            <div className="main-container__google">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d78248.56509433295!2d0.2933339008937328!3d52.202183282770285!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf0974cb4470618e9!2sSwynford%20Manor!5e0!3m2!1sen!2suk!4v1585131864185!5m2!1sen!2suk"
                height="450"
                width="500"
                frameBorder="0"
                title="location"
              >
                Loading...
              </iframe>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default GettingThere
